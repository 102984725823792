const LINKS = {
  internal: {
    home: 'https://oncologycompass.ch',
    login: 'https://oncologycompass.ch/login',
    terms: 'https://oncologycompass.ch/terms',
    policy: 'https://oncologycompass.ch/privacy-policy',
    mailto: 'mailto:info@capptoo.com',
    blog: 'https://blog.oncologycompass.ch/',
  },
  external: {
    msd: 'https://www.msd.ch/',
    capptoo: 'https://capptoo.com',
    amgen: 'https://www.amgen.com/',
    roche: 'https://www.roche.com/',
    takeda: 'https://www.takeda.com/',
  },
};

export default LINKS;