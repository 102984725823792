import React from 'react';
import classnames from 'classnames';

import Link from 'components/Link';
import LINKS from 'constans/links';

const Navigation = ({ links, className, innerClasses, showBlog }) => {
  return (
    <div className="flex-shrink-0">
      <nav>
        <ul className={classnames(
          'flex',
          className
        )}>
          {links.map(({ label, url, order }) => (
            <li key={url + order}>
              <Link
                href={url}
                className={classnames(
                  'font-medium hover:text-text-hover',
                  innerClasses
                )}
              >
                {label}
              </Link>
            </li>
          ))}
          {showBlog && (
            <li>
              <Link
                href={LINKS.internal.blog}
                className={classnames(
                  'font-medium hover:text-text-hover !text-text-hover',
                  innerClasses
                )}
              >Blog</Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
