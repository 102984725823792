import React from 'react';
import { LogIn } from 'react-feather';

import Navigation from 'components/Layout/Navigation';
import { Button } from 'components/ui/Button';
import LINKS from 'constans/links';
import useAllMenu from 'hooks/graphql/useAllMenu';

const HeaderDesktop = () => {
  const navigation = useAllMenu('Main');

  return (
    <div className="flex items-center justify-between">
      <div className="oc-small m-auto">
        <Navigation
          className="space-x-[60px]"
          innerClasses="text-lg font-helv font-bold text-text-3"
          links={navigation}
          showBlog={true}
        />
      </div>

      <div className="flex justify-end h-10 ml-10 pl-10 border-l border-text-hover-op">
        <Button href={LINKS.internal.login} className="text-red font-bold font-helv text-lg px-0 h-10">
          <LogIn />
          <span>Login</span>
        </Button>
      </div>
    </div>
  );
};

export default HeaderDesktop;
