import {
  useStaticQuery,
  graphql
} from 'gatsby';

import categoryLink from 'helpers/categoryLink';

const allCategories = graphql`
  query AllWpCategories {
    allWpCategory(filter: {slug: {ne: "uncategorized"}}) {
      nodes {
        slug
        name
        link
        id
        posts {
          nodes {
            id
          }
        }
      }
    }
  }
`;

const useAllCategoties = (showAll = true) => {
  const {
    allWpCategory: {
      nodes
    },
  } = useStaticQuery(allCategories);

  const currentCategories = nodes
    .filter(({ posts }) => posts.nodes.length > 0)
    .map(category => ({
      ...category,
      link: categoryLink(category)
    }));

  const allCategoriesObj = {
    slug: null,
    name: 'All Categories',
    link: '/',
    id: 'All_Categories',
  };

  return showAll ? [allCategoriesObj, ...currentCategories] : currentCategories;
};

export default useAllCategoties;
