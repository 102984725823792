import React from 'react';
import classnames from 'classnames';

const Button = ({ type = 'button', mod = "", className, children, ...props }) =>
  <button
    type='type'
    className={classnames('btn', mod, className)}
    {...props}
  ><span className="leading-none">{children}</span></button>;

export default Button;