import React from 'react';
import { Bell } from 'react-feather';

import Navigation from 'components/Layout/Navigation';
import Link from 'components/Link';
import { Button } from 'components/ui/Button';
import useAllCategoties from 'hooks/graphql/useAllCategories';
import LINKS from 'constans/links';

import PARTNERS from 'constans/partners';
import useAllMenu from 'hooks/graphql/useAllMenu';

const Top = ({ onConfirm }) => {
  const categories = useAllCategoties(false);
  const navigation = useAllMenu('Footer');

  const handleExternalLink = (e, link) => {
    e.preventDefault();
    onConfirm(link);
  }

  return (
    <div className="flex flex-row">
      <div className="basis-2/3 pb-5">
        <div className="flex flex-row min-h-[12rem]">
          <div className="basis-1/3">
            <Navigation
              className="flex-col space-y-6"
              innerClasses="font-bold"
              links={navigation}
            />
          </div>
          <ul className="basis-1/3 space-y-6">
            <li className="font-bold">Indications</li>
            {categories.map(cat => (
              <ul key={cat.slug}>
                <li>
                  <Link
                    href={`/?category=${cat.slug}`}
                  >{cat.name}</Link>
                </li>
              </ul>
            ))}
          </ul>
          <ul className="basis-1/3 space-y-6">
            <li>
              <Link
                href={LINKS.internal.terms}
                className="hover:text-text-hover"
              >Terms of use</Link>
            </li>
            <li>
              <Link
                href={LINKS.internal.policy}
                className="hover:text-text-hover"
              >Privacy Policy</Link>
            </li>
            <li>
              <Link
                href={LINKS.internal.mailto}
                className="hover:text-text-hover"
              >Contact Us</Link>
            </li>
          </ul>
        </div>
        <Button href={LINKS.internal.login} className="text-red font-bold px-0 h-6 mt-5">
          <Bell />
          <span>Subscribe for updates</span>
        </Button>
      </div>
      <div className="basis-1/3 pl-28 pb-6 border-l border-grey-3">
        <span className="text-xs">Brought to you by</span>
        <ul className="flex items-center pt-2.5 pb-6 space-x-5">
          {PARTNERS.owners.map(owner => (
            <li key={owner.name}>
              <Link href={owner.link}>
                <owner.logo className="h-7" />
              </Link>
            </li>
          ))}
        </ul>
        <span className="text-xs">Generously sponsored by</span>
        <ul className="flex items-center pt-2.5 pb-6 space-x-5">
          {PARTNERS.sponsors.map(sponsor => (
            <li key={sponsor.name}>
              <Link
                href={sponsor.link}
                onClick={e => handleExternalLink(e, sponsor.link)}
              >
                <sponsor.logo />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Top;