import React, { useState } from 'react';
import { Menu as MenuIcon, X as XIcon, LogIn } from 'react-feather';
import classnames from 'classnames';

import Logo from 'assets/logos/logo.inline.svg';
import Link from 'components/Link';
import Navigation from 'components/Layout/Navigation';
import { Button } from 'components/ui/Button';
import LINKS from 'constans/links';

import useAllMenu from 'hooks/graphql/useAllMenu';

const HeaderMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigation = useAllMenu('Main');

  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <MenuIcon />
      </div>

      <div
        className={classnames(
          menuOpen ? 'left-0' : '-left-full',
          'fixed top-0 w-full h-screen bg-white z-20 transition-all'
        )}
      >
        <div className="fluid-container flex justify-between items-center h-16 sm:h-20 lg:h-24 w-full border-b border-grey">
          <p className="w-48 flex-1">
            <Link href={LINKS.internal.home}>
              <Logo />
            </Link>
          </p>
          <div
            className="cursor-pointer"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <XIcon />
          </div>
        </div>
        <div className="fluid-container mt-32">
          <Navigation
            className="flex-col space-y-5"
            innerClasses="text-h2 font-bold"
            showBlog={true}
            links={navigation}
          />
          <Button href={LINKS.internal.login} className="text-red px-0 h-10 text-h2 mt-10 font-bold">
            <LogIn />
            <span>Login</span>
          </Button>
        </div>
      </div>
      <div className={
        classnames(
          'fixed top-0 left-0 w-screen h-screen bg-black transition-all',
          menuOpen ? 'z-10 opacity-50' : '-z-10 opacity-0'
        )
      }></div>
    </div>
  )
}

export default HeaderMobile;