import {
  useStaticQuery,
  graphql
} from 'gatsby';


const flatListToHierarchical = (
  data = [], {
    idKey = 'id',
    parentKey = 'parentId',
    childrenKey = 'children'
  } = {}
) => {
  const tree = [];
  const childrenOf = {};

  data.forEach((item) => {
    const newItem = {
      ...item,
    };
    const {
      [idKey]: id, [parentKey]: parentId = 0
    } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];

    parentId
      ?
      (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem) :
      tree.push(newItem);
  });

  return tree;
};

const allMenus = graphql`
  query getMenus {
    allWpMenu {
      nodes {
        name
        menuItems {
          nodes {
            parentId
            id
            order
            label
            path
            url
            childItems {
              nodes {
                order
                label
                path
              }
            }
          }
        }
      }
    }
  }
`;

const useAllMenus = (nameToMenu) => {
  const {
    allWpMenu: {
      nodes: menus
    },
  } = useStaticQuery(allMenus);

  const menu = menus.find(({
    name
  }) => name === nameToMenu);

  if (!menu) return null;

  const {
    menuItems: {
      nodes
    },
  } = menu;

  return flatListToHierarchical(nodes);
};

export default useAllMenus;
