import React from 'react';
import { X as XIcon } from 'react-feather';

const Modal = ({ onClose, children }) => {
  return (
    <div 
      className="flex items-center justify-center fixed left-0 top-0 w-full h-full z-100 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black before:opacity-50"
      onClick={onClose}
    >
      <div 
        className="relative z-10 bg-white lg:max-w-[960px] w-full h-full lg:h-auto max-h-full px-8 sm:px-20 py-16 lg:shadow-lg overflow-y-auto lg:rounded"
      >
        <button
          className="absolute top-6 right-3.5 w-6 h-6 cursor-pointer"
          onClick={onClose}
        >
          <XIcon />
        </button>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default Modal;