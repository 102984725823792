import React from 'react'
import { Link as LinkElement } from 'gatsby';

const Link = ({ href, children, ...props }) => {
  const path = /(^https?:\/\/)|(^mailto?:)|(^tel:)/i;

  if (path.test(href))
    return <a href={href} {...props}>{children}</a>;

  return <LinkElement to={href} {...props}>{children}</LinkElement>
}

export default Link;
