import React from 'react';
import classnames from 'classnames';
import useWindowSize from 'hooks/useWindowSize';

import Link from 'components/Link';
import Logo from 'assets/logos/logo.inline.svg';
import HeaderDesktop from './Desktop';
import HeaderMobile from './Mobile';
import LINKS from 'constans/links';

const Header = () => {
  const { width: windowWidth } = useWindowSize({ initialWidth: 1024 });

  return (
    <header className="flex border-b border-grey">
      <div
        className={classnames(
          'relative flex items-center left-0 right-0 fluid-container h-16 sm:h-20 lg:h-24 w-full',
        )}
      >
        <p className="w-48 flex-1">
          <Link href={LINKS.internal.home}>
            <Logo />
          </Link>
        </p>

        {windowWidth <= 1024 ? <HeaderMobile /> : <HeaderDesktop />}
      </div >
    </header >
  );
};

export default Header;
