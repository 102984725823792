import React from 'react';

import Modal from 'components/ui/Modal';
import Link from 'components/Link';

const Confirm = ({ link, onClose }) => {
  return (
    <Modal
      onClose={() => onClose()}
    >
      <p className="block relative text-3xl text-medium after:block after:absolute after:left-0 after:bottom-0 after:w-10 after:h-[3px] pb-6 mb-10 after:bg-red">You’re leaving oncologycompass.ch</p>
      <p>We would like to draw your attention to the fact that you are leaving the Oncology Compass website. We hereby expressly declare that we have no influence whatsoever on the design and content of the linked pages and can therefore accept no responsibility for them.</p>
      <div className="flex flex-col sm:flex-row sm:justify-center mt-16 space-y-4 sm:space-y-0 sm:space-x-6">
        <button
          className="flex items-center justify-center text-white bg-text-hover rounded shadow-md hover:shadow-lg w-full sm:w-40 h-16 font-bold"
          onClick={() => onClose()}
        >Cancel</button>
        <Link
          href={link}
          className="flex items-center justify-center text-white bg-red rounded shadow-md hover:shadow-lg w-full sm:w-40 h-16 font-bold"
        >Proceed</Link>
      </div>
    </Modal>
  )
}

export default Confirm;