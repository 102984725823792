import React from 'react';

import Top from './Top';
import Bottom from './Bottom';

const FooterMobile = () => {
  return (
    <div>
      <Top />
      <Bottom />
    </div>
  )
}

export default FooterMobile;