import React from 'react';

import TEXTS from 'constans/texts'; 

const Bottom = () => {
  return (
    <div className="flex items-center justify-center h-20 border-t border-grey-3">
      <span className="text-xs opacity-40 text-grey-4">{TEXTS.copyright}</span>
    </div>
  )
}

export default Bottom;