import React from 'react';
import Bottom from './Bottom';
import Top from './Top';



const FooterDesktop = ({ onConfirm }) => {
  return (
    <div>
      <Top onConfirm={onConfirm} />
      <Bottom />
    </div>
  )
}

export default FooterDesktop;