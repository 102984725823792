import LINKS from './links';
import LogoCapptoo from 'assets/logos/capptoo.inline.svg';
import LogoMSD from 'assets/logos/msd.inline.svg';
import LogoAmgen from 'assets/logos/amgen.inline.svg';
import LogoRoche from 'assets/logos/roche.inline.svg';
import LogoTakeda from 'assets/logos/takeda.inline.svg';

const PARTNERS = {
  owners: [
    {
      name: 'MSD',
      link: LINKS.external.msd,
      logo: LogoMSD,
    },
    {
      name: 'Capptoo',
      link: LINKS.external.capptoo,
      logo: LogoCapptoo,
    },
  ],
  sponsors: [
    {
      name: 'Amgen',
      link: LINKS.external.amgen,
      logo: LogoAmgen,
    },
    {
      name: 'Roche',
      link: LINKS.external.roche,
      logo: LogoRoche,
    },
    {
      name: 'Takeda',
      link: LINKS.external.takeda,
      logo: LogoTakeda,
    },
  ],
};

export default PARTNERS;