import React from 'react';

import Logo from 'assets/logos/logo.inline.svg';
import Link from 'components/Link';
import Navigation from 'components/Layout/Navigation';
import LINKS from 'constans/links';
import TEXTS from 'constans/texts';
import PARTNERS from 'constans/partners';

import useAllMenu from 'hooks/graphql/useAllMenu';

const Top = () => {
  const navigation = useAllMenu('Footer');

  return (
    <div className="">
      <Link href={LINKS.internal.home}>
        <Logo />
      </Link>
      <div className="py-6 border-b border-grey-3">
        <Navigation
          className="flex-wrap"
          innerClasses="font-medium text-sm whitespace-nowrap pr-8"
          links={navigation}
        />
      </div>
      <ul className="flex flex-wrap justify-center space-x-4 text-xs pt-2 pb-6">
        <li className="pt-4">
          <Link
            href={LINKS.internal.terms}
            className="text-blue hover:text-text-hover"
          >Terms of use</Link>
        </li>
        <li className="pt-4">
          <Link
            href={LINKS.internal.policy}
            className="text-blue hover:text-text-hover"
          >Privacy Policy</Link>
        </li>
        <li className="pt-4">
          <Link
            href={LINKS.internal.mailto}
            className="text-blue hover:text-text-hover"
          >Contact Us</Link>
        </li>
        <li className="text-grey-4 opacity-40 pt-4">{TEXTS.index}</li>
      </ul>
      <div className="flex flex-col items-center space-y-4 pb-5">
        <span className="text-xs text-grey-4">Oncology Compass is co-created by</span>
        <ul className="flex flex-wrap space-x-4">
          {PARTNERS.owners.map(owner => (
            <li key={owner.name}>
              <Link href={owner.link}>
                <owner.logo className="h-7" />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Top;