import React, { useState } from 'react';
import classnames from 'classnames';
import useWindowSize from 'hooks/useWindowSize';

import FooterDesktop from './Desktop';
import FooterMobile from './Mobile';
import Confirm from './Confirm';

const Footer = () => {
  const { width: windowWidth } = useWindowSize({ initialWidth: 1024 });

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmLink, setConfirmLink] = useState('');

  const showConfirm = (link) => {
    setConfirmLink(link);
    setModalOpen(true);
  }

  return (
    <footer className="bg-grey-2 mt-10 sm:mt-16 lg:mt-32 font-helv">
      <div className={classnames(
        'fluid-container pt-8 sm:pt-10',
      )}>
        {windowWidth <= 1024 ? (
          <FooterMobile
            onConfirm={showConfirm}
          />
        ) : (
          <FooterDesktop
            onConfirm={showConfirm}
          />
        )}
      </div>
      {modalOpen && (
        <Confirm
          link={confirmLink}
          onClose={() => setModalOpen(false)}
        />
      )}
    </footer>
  );
};

export default Footer;
